import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/src/theme/appModern';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppModern/Navbar';
import Policy from '../containers/AppModern/Policy';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  StaticContentWrapper,
} from '../containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';

export default function() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Yampink" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} className="sticky-active">
            <Navbar hidden={true} />
          </Sticky>
          <StaticContentWrapper>
            <Policy />
          </StaticContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
