import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// Containers
import Container from 'common/src/components/UI/Container';
// Components
import Heading from 'common/src/components/Heading';
import TextList from 'common/src/components/TextList';
import TextListLink from 'common/src/components/TextListLink';
import Text from 'common/src/components/Text';
import TableInfo from 'common/src/components/TableInfo';
// Styles
import SectionWrapper, { InnerWrapper, SectionHeader } from './policy.style';

const Policy = () => {
  const data = useStaticQuery(graphql`
    query {
      appModernJson {
        policy {
          slogan
          articles {
            id
            title
            contents {
              tableInfo {
                fieldName
                fieldValue
              }
              paragraph
              list
            }
          }
        }
      }
    }
  `);
  const { slogan, articles } = data.appModernJson.policy;

  return (
    <SectionWrapper id="policy">
      <Container>
        <SectionHeader>
          <Heading content={slogan} />
        </SectionHeader>

        <InnerWrapper>
          {articles.map(article => (
            <div key={article.id}>
              <Heading as="h3" mt="1.6rem" content={article.title} />

              {article.contents.map(content => (
                <>
                  {content.tableInfo && <TableInfo rows={content.tableInfo} />}
                  {content.paragraph && <Text content={content.paragraph} />}
                  {content.list && <TextList list={content.list} />}
                  {content.list_link && (
                    <TextListLink list={content.list_link} />
                  )}
                </>
              ))}
            </div>
          ))}
        </InnerWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Policy;
